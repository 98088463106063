import React from "react";

function importAll(r) {
  const partnersImage = {};
  r.keys().forEach((key) => {
    const imageName = key.replace("./", "").replace(/\.(png|jpe?g|svg)$/, "");
    partnersImage[imageName] = r(key);
  });
  return partnersImage;
}

const partnersImages = importAll(
  require.context("./assets/images", false, /\.(png|jpe?g|svg)$/)
);

function App() {
  return (
    // <LanguageProvider>
    //   <Header />
    //   <Main />
    //   <Strategy />
    //   <Results />
    //   <Expertises />
    //   <Info />
    //   <Owners />
    //   <Partners />
    //   <Contact />
    //   <Footer />
    // </LanguageProvider>
    <a
      href="https://cbyk.com.br/"
      className="container"
      style={{
        width: "100%",
        height: "100vh",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        background: `#000000`,
      }}
    >
      <picture
        style={{
          width: "100%",
        }}
      >
        <source
          media="(min-width: 768px)"
          srcSet={partnersImages["banner-desktop"]}
          alt="Imagem desktop"
        />
        <source
          media="(max-width: 767px)"
          srcSet={partnersImages["mobile"]}
          alt="Imagem mobile"
        />
        <img
          src={partnersImages["banner-desktop"]}
          alt="Imagem padrão"
          style={{
            width: "100%",
          }}
        />
      </picture>
    </a>
  );
}

export default App;
